<template>
  <div
    class="DateInput"
    @keyup.capture="updateValue"
  >
    <input
      ref="month"
      v-model="month"
      class="month form-control"
      type="text"
      :disabled="disabled"
      placeholder="mm"
      @keydown="updateMonth"
    >
    <span class="divider">/</span>
    <input
      ref="day"
      v-model="day"
      class="day form-control"
      type="text"
      :disabled="disabled"
      placeholder="dd"
      @keydown="updateDay"
    >
    <span class="divider">/</span>
    <input
      ref="year"
      v-model="year"
      class="year form-control"
      type="text"
      :disabled="disabled"
      placeholder="yyyy"
      @keydown="updateYear"
    >
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DateInput',
  props: {
    /* eslint-disable */
    value: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    }
    /* eslint-enable */
  },
  data () {
    let date = null
    if (this.value) {
      date = moment(this.value, 'YYYY-MM-DD')
    }
    const day = date ? `${date.date()}`.padStart(2, 0) : ''
    const month = date ? `${date.month() + 1}`.padStart(2, 0) : ''
    const year = date ? `${date.year()}`.padStart(4, 0) : ''
    return {
      day: day,
      month: month,
      year: year,
      originalValues: {
        day: day,
        month: month,
        year: year
      }
    }
  },
  methods: {
    resetValues () {
      this.setCurrentValues(this.originalValues.day, this.originalValues.month, this.originalValues.year)
      this.updateValue()
    },
    commitValues () {
      this.setOriginalValues(this.day, this.month, this.year)
    },
    setOriginalValues (day, month, year) {
      this.originalValues.day = day
      this.originalValues.month = month
      this.originalValues.year = year
    },
    setCurrentValues (day, month, year) {
      this.day = day
      this.month = month
      this.year = year
    },
    updateMonth (e) {
      if (!this.isKeyAllowed(e)) {
        e.preventDefault()
        return
      }

      const number = this.getNumber(e)
      if (number === false) {
        return
      }

      e.preventDefault()

      if (this.month.length === 2) {
        this.month = ''
      }

      let value = this.month.concat(number)

      if (value.length < 2 && value > 1) {
        value = value.padStart(2, 0)
      }

      if (value.length === 2 && value > 12) {
        value = '12'
      }

      this.month = value

      if (value.length === 2) {
        this.$refs.day.select()
      }
    },
    updateDay (e) {
      if (!this.isKeyAllowed(e)) {
        e.preventDefault()
        return
      }

      if (this.day.length === 0 && this.isBackspace(e)) {
        e.preventDefault()
        this.$refs.month.select()
        return
      }

      const number = this.getNumber(e)
      if (number === false) {
        return
      }

      e.preventDefault()

      if (this.day.length === 2) {
        this.day = ''
      }

      let value = this.day.concat(number)

      if (value.length < 2 && parseInt(value, 10) > 4) {
        value = value.padStart(2, 0)
      }

      if (value.length === 2 && value > 31) {
        value = '31'
      }

      this.day = value

      if (value.length === 2) {
        this.$refs.year.select()
      }
    },
    updateYear (e) {
      if (!this.isKeyAllowed(e)) {
        e.preventDefault()
        return
      }

      if (this.year.length === 0 && this.isBackspace(e)) {
        e.preventDefault()
        this.$refs.day.select()
        return
      }

      const number = this.getNumber(e)
      if (number === false) {
        return
      }

      e.preventDefault()

      if (this.year.length === 4) {
        this.year = ''
      }

      const value = this.year.concat(number)

      this.year = value
    },
    getNumber (e) {
      const numberTop = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
      const numberRight = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105]
      if (!e.shiftKey && numberTop.indexOf(e.keyCode) !== -1) {
        return numberTop.indexOf(e.keyCode)
      }

      if (numberRight.indexOf(e.keyCode) !== -1) {
        return numberRight.indexOf(e.keyCode)
      }

      return false
    },
    isKeyAllowed (e) {
      if ([46, 8, 9, 27, 13, 110].indexOf(event.keyCode) !== -1 ||
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
        (event.keyCode >= 35 && event.keyCode <= 40)) {
        return true
      }

      if (this.getNumber(e) !== false) {
        return true
      }

      return false
    },
    isBackspace (e) {
      return (e.keyCode === 8)
    },
    updateValue () {
      const dateString = this.year + '-' + this.month + '-' + this.day
      const dateExist = dateString.replace(/-/g, '')
      if (!dateExist) {
        this.$emit('input', undefined)
        return
      }
      const date = moment(dateString, 'YYYY-MM-DD', true)
      if (!date.isValid()) {
        this.$emit('input', dateString)
        return
      }
      this.$emit('input', date.format('YYYY-MM-DD'))
    }
  }
}
</script>

<style lang="scss" scoped>
  .DateInput {
    $spacing: 0.75em;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    color: #0a3560;
    background: white;

    input {
      padding: $spacing;
      padding-right: $spacing / 2;
      padding-left: $spacing / 2;
      border: none;
      text-align: center;
      -moz-appearance: textfield;
      box-shadow: none;
    }

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $spacing;
    }

    &:last-child {
      padding-right: $spacing;
    }

    &:focus {
      outline: none;
    }

    .day, .month {
      width: 3em;
    }

    .year {
      width: 4em;
    }

    .divider {
      background: #FFFFFF;
      padding-top: $spacing;
      pointer-events: none;
    }
  }
</style>

<template>
  <div :class="{'programs-enabled': settings.programs_enabled, 'programs-disabled': !settings.programs_enabled}">
    <h3>{{ $t(title) }}</h3>
    <form
      autocomplete="off"
      @submit.prevent="submitPatient"
    >
      <div
        v-if="action === 'update'"
        class="form-group status"
      >
        <label for="status">{{ $t('patientStatus') }}</label>
        <select
          id="status"
          ref="patientStatus"
          v-model="patientForm.status"
          v-validate="{regex: /(.*)\w+/ }"
          name="status"
          class="form-control"
          :disabled="readOnly"
        >
          <option value="active">
            {{ $t('active') }}
          </option>
          <option value="inactive">
            {{ $t('inactive') }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="mrn">{{ $t('mrn') }}</label>
        <input
          id="mrn"
          ref="mrn"
          v-model="patientForm.mrn"
          v-validate="'uniqueMrn'"
          type="text"
          :disabled="readOnly"
          name="mrn"
          class="form-control"
          :data-vv-as="$t('mrn')"
          data-vv-validate-on="blur"
          placeholder="-"
          @input="uniqueMrnInput"
        >
        <input
          id="mrnRequired"
          ref="mrnHidden"
          v-model="patientForm.mrn"
          v-validate="'required'"
          type="hidden"
          :disabled="readOnly"
          name="mrnRequired"
          class="form-control"
          :data-vv-as="$t('mrn')"
          data-vv-validate-on="change"
          placeholder="-"
        >
        <span
          v-show="errors.has('mrn') && !errors.has('mrnRequired')"
          class="error"
        >{{ errors.first('mrn') }}</span>
        <span
          v-show="errors.has('mrnRequired')"
          class="error"
        >{{ errors.first('mrnRequired') }}</span>
      </div>
      <div
        v-if="action === 'update'"
        class="form-group"
      >
        <label for="owlId">{{ $t('owlId') }}</label>
        <input
          id="owlId"
          v-model="patientForm.id"
          type="text"
          name="owlId"
          disabled="disabled"
          class="form-control input-owl-id"
        >
      </div>
      <div class="form-group first_name">
        <label for="firstName">{{ $t('firstName') }}</label>
        <input
          id="firstName"
          v-model="patientForm.first_name"
          v-validate="'required'"
          type="text"
          :disabled="readOnly"
          name="first_name"
          class="form-control"
          :data-vv-as="$t('firstName')"
          placeholder="-"
        >
        <span
          v-show="errors.has('first_name')"
          class="error"
        >{{ errors.first('first_name') }}</span>
      </div>
      <div class="form-group middle_name">
        <label for="middleName">{{ $t('middleName') }}</label>
        <input
          id="middleName"
          v-model="patientForm.middle_name"
          v-validate="{regex: /(.*)\w+/ }"
          type="text"
          :disabled="readOnly"
          name="middle_name"
          class="form-control"
          placeholder="-"
        >
        <span
          v-show="errors.has('middle_name')"
          class="error"
        >{{ errors.first('middle_name') }}</span>
      </div>
      <div class="form-group last_name">
        <label for="lastName">{{ $t('lastName') }}</label>
        <input
          id="lastName"
          v-model="patientForm.last_name"
          v-validate="'required'"
          type="text"
          :disabled="readOnly"
          name="last_name"
          class="form-control"
          :data-vv-as="$t('lastName')"
          placeholder="-"
        >
        <span
          v-show="errors.has('last_name')"
          class="error"
        >{{ errors.first('last_name') }}</span>
      </div>
      <div class="row genderAtBirth">
        <div class="col-5">
          <div class="form-group">
            <label for="genderAtBirth">{{ $t('genderAtBirth') }}</label>
            <select
              id="genderAtBirth"
              v-model="patientForm.gender"
              v-validate="'required'"
              name="genderAtBirth"
              :disabled="readOnly"
              class="form-control"
              :data-vv-as="$t('genderAtBirth')"
            >
              <option
                v-if="!isPatientInformation"
                value=""
              >
                Select
              </option>
              <option value="f">
                {{ $t('female') }}
              </option>
              <option value="m">
                {{ $t('male') }}
              </option>
              <option value="u">
                {{ $t('unknown') }}
              </option>
              <option value="o">
                {{ $t('other') }}
              </option>
              <option value="n">
                {{ $t('gender_non_binary') }}
              </option>
            </select>
            <span
              v-show="errors.has('genderAtBirth')"
              class="error"
            >{{ errors.first('genderAtBirth') }}</span>
          </div>
        </div>
      </div>
      <div class="row birthDate">
        <div class="col-5">
          <div class="form-group">
            <label for="birthDate">{{ $t('birthdate') }}</label>
            <date-input
              ref="birthdateDateInput"
              v-model="patientForm.birth_date"
              v-validate="birthdateRules"
              :disabled="readOnly"
              name="birthDate"
              :data-vv-as="$t('birthdate')"
            />
            <span
              v-show="errors.has('birthDate')"
              class="error"
            >{{ errors.first('birthDate') }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="!behaveAsProgram || action === 'update'"
        class="row clinicians"
      >
        <div class="col-12">
          <div class="form-group">
            <label for="patientClinician">{{ $t('programClinician') }}</label>
            <clinicians-teams-selector
              ref="cliniciansTeamsSelector"
              v-model="selectedClinicians"
              name="clinicians"
              :is-disabled="readOnly"
              :teams-enabled="programsEnabled"
              :data-vv-as="$t('clinician')"
            />
            <input
              v-validate:clinicianFieldValid="`min_value:1`"
              type="hidden"
              :name="clinicianTeamErrorName"
            >
            <span
              v-show="errors.has(clinicianTeamErrorName) && showButtons"
              class="error"
            >{{ errors.first(clinicianTeamErrorName) }}</span>
          </div>
        </div>
      </div>
      <div class="form-group information">
        <p>{{ $t('patientsContactInfo') }}</p>
        <p class="small">
          {{ contactInfoText }}
        </p>
      </div>
      <div class="form-group emailAdress">
        <label for="patientEmail">{{ $t("emailAdress") }}</label>
        <label class="notifications">{{ $t("notifications") }}</label>
        <div class="input-group with-switch">
          <input
            id="patientEmail"
            v-model.trim="patientForm.email"
            v-validate="'email'"
            type="email"
            :disabled="!emailEnabled || readOnly"
            class="form-control"
            name="email"
            placeholder="_"
          >
          <c-switch
            v-if="emailEnabled"
            v-model="patientForm.email_notification"
            v-validate="{regex: /(.*)\w+/ }"
            :enabled="isEmailNotificationEnabled && !readOnly"
            type="default"
            variant="success"
            :pill="true"
            name="email_notification"
            @change="changeEmailNotification"
          />
          <span
            v-else
            class="sms-disabled-clinic"
          >{{ $t('emailDisabledClinic') }}</span>
        </div>
        <span
          v-show="errors.has('email')"
          class="error"
        >
          {{ errors.first('email') }}
        </span>
        <div
          v-if="patient.email_emr && isEmrEmail"
          class="emr-contact"
        >
          EMR: <span>{{ patient.email_emr }}</span>
        </div>
      </div>
      <div class="form-group phoneNumber">
        <label for="patientPhone">{{ $tc("phoneNumber", 1) }}</label>
        <label class="notifications">{{ $t("notifications") }}</label>
        <div class="input-group with-switch">
          <masked-input
            id="patientPhone"
            v-model="patientForm.mobile_number"
            data-vv-validate-on="change"
            type="tel"
            autocomplete="off"
            class="form-control"
            :disabled="readOnly || !smsEnabled"
            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            :guide="true"
            :show-mask="true"
            name="mobile"
            placeholder-char="_"
          />
          <c-switch
            v-if="smsEnabled"
            v-model="patientForm.mobile_notification"
            v-validate="{regex: /(.*)\w+/ }"
            :enabled="isMobileNotificationEnabled && !readOnly"
            type="default"
            variant="success"
            :pill="true"
            name="mobile_notification"
            @change="changeMobileNotification"
          />
          <span
            v-else
            class="sms-disabled-clinic"
          >{{ $t('smsDisabledClinic') }}</span>
        </div>
        <input
          id="mobile"
          ref="mobile"
          v-model="mobile_clean"
          v-validate="'min:10|max:10'"
          type="hidden"
          name="mobile"
          data-vv-validate-on="input"
          data-vv-as="Phone"
        >
        <span
          v-show="errors.has('mobile')"
          class="error"
        >
          {{ errors.first('mobile') }}
        </span>
        <div
          v-if="patient.mobile_number_emr && isEmrPhone"
          class="emr-contact"
        >
          EMR: <span>{{ patient.mobile_number_emr | phoneFormat }}</span>
        </div>
      </div>
      <div
        v-if="showButtons"
        class="buttons"
      >
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </button>
        <button
          type="submit"
          :disabled="disableSubmitButton"
          class="btn btn-secondary"
        >
          {{ saveText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import cSwitch from '@/components/common/Switch'
import DateInput from '@/components/common/DateInput'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { INACTIVE_USER_STRING } from '@/store/modules/user/constants.js'
import CliniciansTeamsSelector from '@/components/common/CliniciansTeamsSelector'
import {
  SS_PROGRAM_OPTIONS,
  SS_APPOINTMENT_OPTIONS
} from '@/data/setttingStates'

const REQUIRED_CLINICIANS_ERROR_CREATE = 'cliniciansHiddenCreate'
const REQUIRED_CLINICIANS_ERROR_MEASURES = 'cliniciansHiddenMeasures'
const REQUIRED_CLINICIANS_ERROR_PROGRAMS = 'cliniciansHiddenPrograms'

export default {
  name: 'ClinicianPatientForm',
  components: {
    MaskedInput,
    cSwitch,
    DateInput,
    CliniciansTeamsSelector
  },
  filters: {
    phoneFormat: function (phone) {
      const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    }
  },
  mixins: [Helpers, ErrorsDictionary, PatientHelper],
  props: {
    /* eslint-disable */
    action: {},
    patient: {},
    readOnly: {
      default: true
    }
    /* eslint-enable */
  },
  data: function () {
    const preSelectedProgramId = this.$store.getters.preSelectedProgramId || this.$route.name === 'ClinicianPatientInformation'
    const appointmentsEnabled = SS_APPOINTMENT_OPTIONS.includes(this.$store.getters.generalSettings.setting_state)
    const programsEnabled = SS_PROGRAM_OPTIONS.includes(this.$store.getters.generalSettings.setting_state)
    const formBehavior = this.$getPatientFormBehavior(appointmentsEnabled, programsEnabled, preSelectedProgramId)
    const patientForm = Object.assign({}, this.patient)
    const behaveAsProgram = formBehavior === this.$getPatientFormProgramBehaviorId()
    if (this.action === 'create' && behaveAsProgram) {
      delete patientForm.clinicians
    }

    return {
      todaysDate: null,
      submitState: false,
      appointmentsEnabled: appointmentsEnabled,
      behaveAsProgram: behaveAsProgram,
      formBehavior: formBehavior,
      originalPatientForm: { ...patientForm },
      patientForm: patientForm,
      birth_date: this.patient.birth_date,
      mobile_clean: '',
      disabledDates: {
        from: new Date()
      },
      selectedClinicians: {
        clinicians: [...patientForm.clinicians || []],
        teams: [...patientForm.clinician_teams || []],
        teamsFromPrograms: [...patientForm.clinician_teams_programs || []]
      },
      mobileNotification: this.patient.mobile_notification,
      emailNotification: this.patient.email_notification,
      originalMobileNotification: this.patient.mobile_notification,
      originalEmailNotification: this.patient.email_notification
    }
  },
  computed: {
    ...mapGetters({
      clinicians: 'getClinicians',
      settings: 'generalSettings',
      formDirty: 'getActiveFormDirty'
    }),
    birthdateRules () {
      return {
        required: true,
        date_format: 'YYYY-MM-DD',
        date_between: ['1900-01-01', this.todaysDate, false]
      }
    },
    isEmrEmail () {
      return this.patient.email_emr.length && this.patient.email !== this.patient.email_emr
    },
    isEmrPhone () {
      return this.patient.mobile_number_emr.length && this.patient.mobile_number !== this.patient.mobile_number_emr
    },
    disableSubmitButton () {
      if (this.errors.items.length) {
        return true
      }

      if (this.submitState) {
        return true
      }

      return false
    },
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    title () {
      return `${this.action}Patient`
    },
    isPatientInformation () {
      return this.$route.name === 'ClinicianPatientInformation'
    },
    contactInfoText () {
      return this.isPatientInformation ? this.$t('contactInfoNotificationMessage') : this.$t('patientsWillReceiveNotifications')
    },
    showButtons () {
      if (this.readOnly) { return false }
      if (!this.isPatientInformation) { return true }

      const keys = Object.keys(this.fields)
      if (!keys.length) {
        return false
      }
      return this.formDirty
    },
    saveText () {
      return this.action === 'update' ? this.$t('confirm') : this.$route.name === 'SettingsPatientUsers' ? this.$t('save') : this.$t('continue')
    },
    isEmailNotificationEnabled () {
      return !!((!this.errors.has('email') && this.patientForm && this.patientForm.email))
    },
    isMobileNotificationEnabled () {
      return (!this.errors.has('mobile') && this.mobile_clean)
    },
    clinicianTeamErrorName () {
      const hasClinicians = this.selectedClinicians && this.selectedClinicians.clinicians && this.selectedClinicians.clinicians.length
      const hasClinicianTeams = this.selectedClinicians && this.selectedClinicians.teams && this.selectedClinicians.teams.length
      const hasClinicianTeamsFromPrograms = this.selectedClinicians && this.selectedClinicians.teamsFromPrograms && this.selectedClinicians.teamsFromPrograms.length
      const hasSomething = (hasClinicians || hasClinicianTeams || hasClinicianTeamsFromPrograms)

      // When creating, clinician team is always required.
      if (!hasSomething && this.action === 'create') {
        return REQUIRED_CLINICIANS_ERROR_CREATE
      }

      // If changing user status to inactive, or status is inactive can remove clinicians.
      if ([this.patientForm.status, this.originalPatientForm.status].includes(INACTIVE_USER_STRING)) {
        return null
      }

      // When user has active programs assigned show programs error.
      if (!hasSomething && this.activePatientProgramsAssignation.length) {
        return REQUIRED_CLINICIANS_ERROR_PROGRAMS
      }

      // When user has pending measures assigned show Measures error.
      if (!hasSomething && (this.patient.has_measures_assigned || this.patient.team_has_pending_measures)) {
        return REQUIRED_CLINICIANS_ERROR_MEASURES
      }

      return null
    },
    clinicianFieldValid () {
      return this.clinicianTeamErrorName === null ? 1 : 0
    },
    computedForm () {
      return Object.assign({}, this.patientForm)
    }
  },
  watch: {
    patient: {
      handler (n, o) {
        for (const key in this.originalPatientForm) {
          if (n.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
            this.originalPatientForm[key] = n[key]
            this.patientForm[key] = n[key]
          }
        }
      },
      deep: true
    },
    'patient.email' (newValue) {
      this.patientForm.email = newValue
      this.resetValidator()
    },
    'patient.email_notification' (newValue) {
      this.patientForm.email_notification = newValue
      this.changeEmailNotification()
      this.resetValidator()
    },
    'patient.mobile_notification' (newValue) {
      this.patientForm.mobile_notification = newValue
      this.changeMobileNotification()
      this.resetValidator()
    },
    'patient.mobile_number' (newValue) {
      this.patientForm.mobile_number = newValue
      this.resetValidator()
    },
    'patientForm.mobile_number' (n, o) {
      if (this.getCleanPhone(n) !== this.getCleanPhone(o)) {
        this.setCleanPhone(n)
      }
    },
    'patientForm.clinicians' () {
      if (this.clinicianFieldValid) {
        this.$validator.errors.remove(REQUIRED_CLINICIANS_ERROR_CREATE)
        this.$validator.errors.remove(REQUIRED_CLINICIANS_ERROR_PROGRAMS)
        this.$validator.errors.remove(REQUIRED_CLINICIANS_ERROR_MEASURES)
      } else {
        this.$validator.errors.add(this.clinicianTeamErrorName, this.$t(`clinicianFields.${this.clinicianTeamErrorName}`))
      }
    },
    computedForm: {
      handler (n, o) {
        const isDirty = Object.keys(this.patientForm).some(field => {
          const oldValue = field === 'mobile_number' ? this.getCleanPhone(o[field]) : o[field]
          const newValue = field === 'mobile_number' ? this.getCleanPhone(n[field]) : n[field]
          return newValue !== oldValue
        })
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', isDirty)
      },
      deep: true
    },
    isMobileNotificationEnabled (newValue) {
      if (!newValue) {
        this.patientForm.mobile_notification = false
        return
      }
      this.patientForm.mobile_notification = this.mobileNotification
    },
    isEmailNotificationEnabled (newValue) {
      if (!newValue) {
        this.patientForm.email_notification = false
        return
      }
      this.patientForm.email_notification = this.emailNotification
    },
    selectedClinicians (n, o) {
      if (n !== o) {
        this.patientForm.clinicians = n ? n.clinicians : []
      }
    }
  },
  beforeMount () {
    const uniqueMrn = () => {
      return new Promise(resolve => {
        this.$store.dispatch('VALIDATE_MRN', this.patientForm).then((data) => {
          if (!data.available) {
            if (this.action !== 'update') {
              const promptOptions = {
                message: this.$t('mrnExistEditPatient'),
                cancelButton: this.$t('noResume'),
                okButton: this.$t('yesView')
              }
              this.$promptBeforeAction(promptOptions, () => {
                return this.$router.push({ name: 'ClinicianEditPatient', params: { patientId: data.access_control_id } })
              }, () => {
                this.$refs.mrn.focus()
                this.$validator.reset()
              })
            }

            return resolve({
              valid: false,
              data: {
                message: this.$t('mrnInUse')
              }
            })
          }

          return resolve({
            valid: true
          })
        }).catch(() => {
          return resolve({
            valid: false,
            data: {
              message: this.$t('generalError')
            }
          })
        })
      })
    }

    this.$validator.extend('uniqueMrn', {
      validate: uniqueMrn,
      getMessage: (field, params, data) => data.message
    })
  },
  created () {
    if (this.patient.birth_date) {
      this.birth_date = moment(this.patient.birth_date).toDate()
    }
    this.mobile_clean = this.getCleanPhone(this.patient.mobile_number)
    this.$validator.localize('en', this.errorsDictionary)
    this.todaysDate = new Date().toISOString().slice(0, 10)
  },
  destroyed () {
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    uniqueMrnInput () {
      this.$nextTick().then(() => {
        this.$validator.validate('mrnRequired')
      })
    },
    resetValidator () {
      this.$nextTick().then(() => {
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
      })
    },
    setOriginalContactInfo () {
      this.originalMobileNotification = this.mobileNotification
      this.originalEmailNotification = this.emailNotification
    },
    changeMobileNotification () {
      this.setDirty('mobile_notification')
      this.mobileNotification = this.patientForm.mobile_notification
    },
    changeEmailNotification () {
      this.setDirty('email_notification')
      this.emailNotification = this.patientForm.email_notification
    },
    cancel () {
      this.$emit('cancel')
      if (this.$route.name === 'ClinicianPatientInformation') {
        this.patientForm = { ...this.originalPatientForm }
        this.$refs.birthdateDateInput.resetValues()
        if (this.$refs.cliniciansTeamsSelector) {
          this.$refs.cliniciansTeamsSelector.resetValues()
        }

        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
      }
    },
    submitPatient () {
      if (!this.readOnly) {
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            this.submitState = true
            const patientData = { ...this.patientForm }
            patientData.clinicians = this.selectedClinicians ? this.selectedClinicians.clinicians : []
            patientData.clinician_teams = this.selectedClinicians ? this.selectedClinicians.teams : []
            patientData.mobile_number = this.mobile_clean
            this.$refs.birthdateDateInput.commitValues()
            if (this.$refs.cliniciansTeamsSelector) {
              this.$refs.cliniciansTeamsSelector.commitValues()
            }
            this.showNotificationToast()
            this.$emit('submitPatient', { ...patientData, formDirty: this.formDirty })
            this.patientForm = { ...patientData }
            this.originalPatientForm = { ...this.patientForm }
            this.setOriginalContactInfo()
          }
        })
      }
    },
    submited () {
      this.submitState = false
    },
    setClinicianChange () {
      this.cliniciansChanged = true
    },
    getCliniciansId () {
      return this.selectedClinicians.map(item => parseInt(item.id))
    },
    setCleanPhone (phone) {
      phone = this.getCleanPhone(phone)
      this.mobile_clean = phone
      if (phone) {
        this.setDirty('mobile')
      }
    },
    setBirthDate (date) {
      this.setDirty('birthDate')
      this.birth_date = date
    },
    showNotificationToast () {
      let alertMsg = false

      if (!this.patientForm.email && !this.patientForm.mobile_number.replace(/\D/g, '')) {
        alertMsg = this.$t('noContactInfo')
      } else if (!this.canSendNotification()) {
        alertMsg = this.$t('notificationsOff')
      }
      if (!alertMsg) {
        return
      }

      this.$toast.warning({ message: this.getNotificationMessage(alertMsg) })
    },
    getNotificationMessage (msg) {
      if (this.action === 'update' && (this.originalEmailNotification || this.originalMobileNotification)) {
        return this.$t('alertCantSendNotificationOnUpdate')
      }

      return this.$t('alertCantSendNotification', { text: msg })
    },
    canSendNotification () {
      return (this.patientForm.email && this.patientForm.email_notification) || (this.patientForm.mobile_number && this.patientForm.mobile_notification)
    },
    setDirty (field) {
      this.$validator.flag(field, { dirty: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.error {
  float: none;
  padding-bottom: 0;
}
.emr-contact {
  margin: 8px 0 0 12px;
  font-size: 12px;
  font-weight: 300;
  span {
    color: #8097AD;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>

<template>
  <div class="clinician-patient-form">
    <clinician-patient-form
      v-if="loaded"
      :key="patient.id"
      ref="patientForm"
      :action="action"
      :patient="patient"
      :read-only="patientInformationReadOnly"
      @submitPatient="submitPatient"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClinicianPatientForm from '@/components/clinician/patients/ClinicianPatientForm'
import { PatientHelper } from '@/mixins/PatientHelper'
import { Helpers } from '@/mixins/Helpers'
import {
  SS_PROGRAM_OPTIONS,
  SS_APPOINTMENT_OPTIONS
} from '@/data/setttingStates'

export default {
  name: 'ClinicianPatientFormWrapper',
  components: {
    ClinicianPatientForm
  },
  mixins: [Helpers, PatientHelper],
  props: ['stayAfterFinish'],
  data () {
    const preSelectedProgramId = this.$store.getters.preSelectedProgramId
    const appointmentsEnabled = SS_APPOINTMENT_OPTIONS.includes(this.$store.getters.generalSettings.setting_state)
    const programsEnabled = SS_PROGRAM_OPTIONS.includes(this.$store.getters.generalSettings.setting_state)
    const formBehavior = this.$getPatientFormBehavior(appointmentsEnabled, programsEnabled, preSelectedProgramId)
    return {
      loaded: false,
      behaveAsProgram: formBehavior === this.$getPatientFormProgramBehaviorId()
    }
  },
  computed: {
    action () {
      return this.$route.params.patientId || this.$route.name === 'ClinicianPatientInformation' ? 'update' : 'create'
    },
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    actionText () {
      if (this.$refs.patientForm.fields.status && this.$refs.patientForm.fields.status.dirty) {
        return this.patient.status === 'active' ? 'activated' : 'inactivated'
      }
      return this.action === 'update' ? 'updated' : 'added'
    },
    ...mapGetters({
      patient: 'getSinglePatient',
      clinicianLogged: 'loggedInUser'
    })
  },
  watch: {
    '$route.params': function () {
      this.fetchPatient()
    }
  },
  created () {
    this.fetchPatient()
  },
  methods: {
    cancel () {
      if (this.$parent.beforeClose) {
        this.$parent.beforeClose()
      }
    },
    fetchPatient () {
      let patientId = null
      this.loaded = false
      if (this.action === 'update') {
        patientId = this.$route.name === 'ClinicianPatientInformation' ? this.activeAssignment.user_id : this.$route.params.patientId
      }
      this.getPatient(patientId).finally(() => {
        this.loaded = true
      })
    },
    submitPatient (patient) {
      const formDirty = patient.hasOwnProperty('formDirty') ? patient.formDirty : true // eslint-disable-line no-prototype-builtins
      const action = this.action === 'update' ? 'UPDATE_PATIENT' : 'CREATE_PATIENT'
      const loader = this.$loading.show()
      const invitationSent = this.patient.invitation_sent

      if (!this.behaveAsProgram) {
        patient.clinician_logged = this.clinicianLogged.id
      }

      this.$store.dispatch(action, patient).then(response => {
        if (response && response.status !== 200) {
          return this.handleSubmitPatientErrors(response)
        }

        if (Number(invitationSent) !== 1 && Number(response.data.invitation_sent) === 1) {
          this.$toast.success({ message: this.$t('invitationSent') })
        }

        if (formDirty) {
          this.$toast.success({ message: this.$t('patientSumbit', { action: this.actionText }) })
        }

        // Would be ideal to do this generically OWL-6556 - Supervisor does not have access to patient data after discharge or team change.
        if (response.data.hasOwnProperty('cancelActions') && response.data.cancelActions) { // eslint-disable-line no-prototype-builtins
          return true
        }

        this.getRespondentLatestSession(response.data.ccau_id)
        if (this.$route.name !== 'ClinicianPatientInformation') {
          if (this.stayAfterFinish) {
            return this.$emit('closeOverlay')
          }
          return this.redirectAfterCreate(response)
        }

        this.$store.dispatch('GET_ASSIGNMENTS', response.data.assignment_id).then(() => {
          this.$store.dispatch('SET_ASSIGNMENT_VIEW', response.data.client_clinic_assignment_user_id)
        }).catch(() => {
          this.$refs.respondentForm.submited()
          this.$router.push({ name: 'PageNotFound' })
        }).finally(() => {
          loader.hide()
        })
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        loader.hide()
        if (this.$refs.patientForm) {
          this.$refs.patientForm.submited()
          this.$refs.patientForm.$validator.reset()
        }
      })
    },
    handleSubmitPatientErrors (response) {
      const $validator = this.$validator
      this.$handleApiError(response)
      Object.keys(response.data.errors).forEach(function (field) {
        $validator.errors.add(field, response.data.errors[field])
      })
    },
    redirectAfterCreate (response) {
      const params = { patientId: this.$getEncodedId(response.data.id) }
      if (this.behaveAsProgram) {
        return this.$router.push({ name: 'ClinicianAddProgramToPatient', params: params, query: { justAdded: 1 } })
      }

      params.ccauId = this.$getEncodedId(response.data.client_clinic_assignment_user_id)
      params.ccaId = this.$getEncodedId(response.data.assignment_id)
      return this.$router.push({ name: 'ClinicianPatientAddMeasure', params: params, query: { justAdded: 1 } })
    }
  }
}
</script>
